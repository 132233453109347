// node modules
import { memo } from 'react';
import { nanoid } from 'nanoid';

// components
import Card from './Card';

// Themes
import theme from '@/themes/index';

const CardsDesktopContainer = ({
  cards, 
}) => (
  <>
    <div className='cards-container'>
      {cards.map(({
        card,
      }) => (
        <div key={nanoid()} className='cards'>
          <Card {...card} />
        </div>
      ))}
    </div>
    <style jsx>{`
      div {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        justify-content: center;
      }

      div.cards {
        width: 46rem !important;
      }

      @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
        .cards-container {
          gap: 1.5rem;
        }
      }

      @media only screen and (min-width: ${theme.breakpoints.DesktopMin}) {
        .cards-container {
          margin: 0 24.6rem;
        }
      }
    `}</style>
  </>
);

export default memo(CardsDesktopContainer);
