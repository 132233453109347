// Node Modules
import { nanoid } from 'nanoid';
import { RichText } from 'prismic-reactjs';

// Components
import Carousel from '@/components/global-components/carousel/Carousel';
import Card from './sub-components/Card';
import CardsDesktopContainer from './sub-components/CardsDesktopContainer';
import Container from '@/components/global-components/Container';

// Themes
import theme from '@/themes/index';

const Cards = ({
  isMobile,
  isDesktop,
  showAlternateHeader,
  content: {
    title,
    subTitle,
    cards,
  },
}) => {
  let cardListForDesktop = [];

  if (!isMobile) {
    cardListForDesktop = cards.reduce(
      (accumulator, currentValue, i) =>
        (i % 3
          ? accumulator[accumulator.length - 1].push(currentValue)
          : accumulator.push([currentValue])) && accumulator,
      []
    );
  }

  const getCardsForDesktopViewPort = () => {
    let startCardIndex = 0;

    return (
      <Carousel
        cellSpacing={20}
        showButtons={cardListForDesktop.length > 1}
        showPaging={cardListForDesktop.length > 1}
        containerClassName='top-cards-carousel'
      >
        {cardListForDesktop.map(() => {
          const children = cards.slice(startCardIndex, startCardIndex + 3);
          startCardIndex += 3;

          return (
            <Container key={nanoid()} >
              <CardsDesktopContainer cards={children} />
            </Container>
          );
        })}
      </Carousel>
    );
  };

  const getCardsForMobileViewPort = () => (
    <Carousel slidesToShow={1.1} cellSpacing={15} showPaging={true}>
      {cards.map(({
        card,
      }) => (
        <Card key={nanoid()} isMobile={isMobile} {...card} />
      ))}
    </Carousel>
  );

  return (
    <>
      <div className='container'>
        {showAlternateHeader ? (
          <>
            <div className='header-container'>
              <h6>{title}</h6>
            </div>
          </>
        ) : (
          <div className='header-container'>
            <h2>{title}</h2>
            <RichText render={subTitle} />
          </div>
        )}

        <div className='carousel'>
          {!isDesktop
            ? getCardsForMobileViewPort()
            : getCardsForDesktopViewPort()}
        </div>
      </div>
      <style jsx>{`
        div.container {
          width: 100%;
          text-align: ${showAlternateHeader ? 'left' : 'center'};
          display: flex;
          flex-direction: column;
        }

        div.container h6 {
          font-family: ${theme.fontFamilies.montserratBold};
          text-decoration: underline;
          margin-block-start: 0;
          margin-block-end: 0;
        }

        div.header-container {
          flex-direction: row;
          padding-bottom: 0.5rem;
          text-align: center;
        }

        div.carousel {
          flex-direction: row;
          margin-top: 1.125rem;
        }

        p,
        h3 {
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0;
          margin-inline-end: 0;
        }

        h3 {
          font-family: ${theme.fontFamilies.montserratMedium};
          line-height: 3.5rem;
          padding-bottom: 2rem;
        }

        p {
          padding: 0 1.5rem;
          font-family: ${theme.fontFamilies.montserrat};
          line-height: 1.1rem;
        }

        span {
          margin-bottom: 1.5rem;
          font-family: ${theme.fontFamilies.montserrat};
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          h3 {
            line-height: 5.375rem;
            padding-bottom: 0.686rem;
          }

          p {
            line-height: 1.5rem;
          }
        }
      `}</style>
      <style global jsx>{`
        div.carousel .slider-control-bottomcenter {
          bottom: -15% !important;
          left: 50% !important;
        }

      
        .slider {
          margin-bottom: 5rem !important;
        }

        div.header-container p {
          text-align: center;
        }
        
        div.header-container p a {
          color: ${theme.fontColors.primary};
        }
      `}</style>
    </>
  );
};

export default Cards;
