// Node Modules
import { nanoid } from 'nanoid';
import { RichText } from 'prismic-reactjs';

// Components
import Button from '@/components/global-components/Button';

// Themes
import theme from '@/themes/index';

const defaultProps = {
  isMobile: false,
  description: '',
};

const Card = ({
  isMobile,
  title,
  description,
  type,
  callToActions = [],
  status,
  url,
  image,
}) => {
  return (
    <>
      <div className='container card-container'>
        <img className='card-picture' src={image?.url} alt={image?.alt} />
        <div className='card-details'>
          
          {url ? (
            <a className='card-title' href={url.url} target={url.target} rel="noreferrer">{title}</a>
          ) : (
            <h6 className='card-title'>{title}</h6>
          )}
          <RichText render={description} />
          {isMobile && <span className='type'>{type}</span>}
          <div className='cta-container'>
            {callToActions.map(({
              callToAction: {
                title = '',
                url = '',
                style = '',
                openInAnotherPage = false,
              } = {},
            }) =>
              <Button
                key={nanoid()}
                target={openInAnotherPage ? '_blank' : '_self'}
                className={style.toLowerCase()}
                text={title}
                href={url}
                tabIndex={0}
              />
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        div.container {
          width: 100%;
        }

        img.card-picture {
          width: 100%;
          height: 25rem;
          object-fit: cover;
          object-position: 0% 10%;
        }

        div {
          display: flex;
          flex-direction: column;
          text-align: left;
        }

        div.cta-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          padding: 0 1.2rem;
        }

        div span {
          font-family: ${theme.fontFamilies.openSans};
          margin: 0 0 0.5rem;
          color: ${theme.fontColors.optional3};
        }

        div span.status {
          font-family: ${theme.fontFamilies.openSansBold};
          color: ${theme.fontColors.optional2};
          font-size: 1.3rem;
        }

        div span.type {
          margin-top: 0.5rem;
        }

        div.card-details {
          padding-top: 0.5rem;
        }

        

        @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
          div.cta-container {
            margin-top: 0.75rem;
            justify-content: center;
            padding: 0 7.2rem;
          }

          div.card-details {
            padding-top: 1.5rem;
            min-height: 230px;
            justify-content: space-between;
          }
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.cta-container {
            margin-top: 0.75rem;
            justify-content: center;
            padding: 0 1.2rem;
          }

          div.card-details {
            padding-top: 1.5rem;
          }
        }
      `}</style>
      <style global jsx>{`

        .card-container h6.card-title,
        .card-container a.card-title {
          font: normal normal normal 1.5rem/2rem MontserratRegular;
          margin: 0 0 0.5rem;
          color: ${theme.fontColors.primary};
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0;
          font-weight: bold;
          margin-inline-end: 0;
        }

        .card-container a.card-title {
          font-weight: bold;
          color: ${theme.fontColors.primary};
          text-decoration: none;
        }
      `}
      </style>
    </>
  );
};

Card.defaultProps = defaultProps;

export default Card;
